import { Button } from 'app/components/button/common-button';
import { Icon } from 'app/components/icon/icon';
import { checkUserAccess } from 'app/redux/hooks/check-user-access';
import { Row, styled, Text, useSx, View } from 'dripsy';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useRouter } from 'solito/router';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import Tooltip from 'app/components/tooltip';
import { I18Text } from 'app/provider/i18-provider';

const StyledText = styled(Text)({
  marginLeft: 1,
});

const StyledRow = styled(Row)({
  alignItems: 'center',
});

interface ExamCardProps {
  data: any;
  type?: string;
  user: any;
  permission: any;
  client: any;
}

function ExamCard({ data, type = '', user, permission, client }:ExamCardProps) {
  const sx = useSx();
  const { push } = useRouter();
  const { clientDetail } = client || {};
  const currentRoute = useCurrentRoute();
  const userPermission = useMemo(() => {
    return {
      isAdmin:
        checkUserAccess({
          user,
          permission,
          resourceSlug: 'admin_test_platform_exam',
        }).allowed || user?.role == 65535,
      isMarkEdit: data?.examiner?.includes(user?._id),
      isEdit: typeof data?.createdBy!=='undefined' && typeof user?._id!=='undefined' && data?.createdBy === user?._id,
    };
  }, [user?._id, data]);

  const handleViewDetails = () => {
    push(
      '/exams/[pageType]/[subPages]',
      `/exams/${
        userPermission?.isAdmin || userPermission?.isMarkEdit
          ? 'exam-report'
          : 'result'
      }/${data._id}`
    );
  };

  const appearExam = () => {
    push('/[pageType]/[subPages]', `/${'appear-exam'}/${data._id}`);
  };

  const getDate = () => {
    let date = data?.creationTime;
    if (type === 'upcoming') {      
      date = data?.startTime;
    } else {
      date = data?.creationTime;
    }
    return date;
  };

  const handleBuy = async (exam: any) => {
    if (exam?._id) {
      exam?.examCheck?.purchaseType === 'product'
        ? push(`/client-checkout/product/${exam?.examCheck?.productTypeId}`)
        : push(`/client-subscription?continue=${currentRoute}&productId=${exam?.competitive && exam?.competitiveExam?.length ? exam?.competitiveExam[0]:''}&subscriptionId=${exam?.examCheck?.productTypeId}`);
    }
  };

  return (
    <View
      style={[
        sx({
          margin: [1, 2],
          minHeight: [170],
          paddingX: [2, 3],
          paddingY: 2,
          borderRadius: 6,
          backgroundColor: '$white',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.22,
          shadowRadius: 2.22,
          elevation: 3,
          justifyContent: 'space-between',
          minWidth: [320, 360],
        }),
      ]}
    >
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={ { display:'flex',flexDirection:'row' } }>
          <Tooltip content={data.name} position='bottom'    style={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingLeft: 10,
              zIndex: 100,
            }}>
          <Text numberOfLines={1} style={{ fontWeight: 'bold', flex: 1,width:150 }}>{data?.name}{' '}</Text>
          </Tooltip>
          {data?.competitive ? (
            <I18Text
              style={{
                borderStyle: 'solid',
                borderLeftWidth: 2,
                paddingLeft: 8,
                fontWeight: '400',
                color: 'grey',
              }}
            >
              Competitive
            </I18Text>
          ) : (
            <></>
          )}
        </View>
        {(userPermission?.isAdmin || userPermission?.isEdit) &&
        !['evaluation-pending', 'ongoing', 'completed'].includes(type) ? (
          <Button
            variantType="text"
            style={{ minWidth: 30, minHeight: 30, borderRadius: 30, margin: 0 }}
            onPress={() =>
              push(
                '/exams/[pageType]/[subPages]/[pageDetails]',
                `/exams/edit-exam/exam-information/${data._id}`
              )
            }
          >
            <Icon name="editPen" />
          </Button>
        ) : (
          <></>
        )}
      </Row>
      {type === 'upcoming' && <Text>{moment(getDate())?.format('DD MMM YYYY, h:mm a')}</Text>}
        <View>
        {type === 'ongoing' && (
          <>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <Icon height={35} width={35} name="calendar" />
              <View>
                <StyledText>
                  <I18Text>Date</I18Text>: {moment(data?.startTime)?.format('DD MMM YYYY')}
                </StyledText>
                <StyledText>
                  <I18Text>Time</I18Text>: {moment(data?.startTime)?.format('h:mm a')}
                </StyledText>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 8,
                marginLeft: 4,
                padding: 2
              }}
            >
              {data?.duration && (
                <StyledRow>
                  <Icon height={20} width={20} name="timer" />
                  <StyledText>
                    <I18Text>Duration</I18Text>:
                    {`${data?.duration} ${data?.duration === 1 ? 'minute' : 'minutes'}`}
                  </StyledText>
                </StyledRow>
              )}
            </View>
          </>
        )}
        {type == 'practice' && (
          <Row
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            <StyledRow>
              <Icon height={15} width={15} name="question" />
              <StyledText>
                <I18Text>Questions</I18Text> : {data?.totalNoOfQuestions || 0}
              </StyledText>
            </StyledRow>
            <StyledRow>
              <Icon height={15} width={15} name="duration" />
              <StyledText><I18Text>Duration</I18Text> : {data?.duration}</StyledText>
            </StyledRow>
            <StyledRow>
              <Icon height={15} width={15} name="marks" />
              <StyledText><I18Text>Marks</I18Text> : {data?.totalMarks}</StyledText>
            </StyledRow>
          </Row>
        )}
        <Row
          style={sx({
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTopColor: '$gray',
            borderTopWidth: 1,
            paddingTop: 2,
          })}
        >
{(['completed', 'evaluation-pending'].includes(type) && 
  (!(type === 'completed' && data?.disPlayResult && data?.disPlayResult === 'notDisclose') || userPermission?.isAdmin )) ? (
  <Row
    style={sx({
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 2,
      width: '100%',
    })}
  >
    <Button
      variantType="text"
      style={{
        minWidth: 100,
        minHeight: 25,
        borderRadius: 30,
        margin: 0,
      }}
      onPress={handleViewDetails}
      disabled={
        !userPermission?.isAdmin &&
        !userPermission?.isMarkEdit &&
        type == 'evaluation-pending'
      }
    >
      <I18Text>
        {type == 'evaluation-pending' &&
        (userPermission?.isAdmin || userPermission?.isMarkEdit)
          ? 'Assign mark'
          : 'View report'}
      </I18Text>
    </Button>
    {data.retakeLeft ? (
      <Button
        variantType="text"
        style={{
          minWidth: 100,
          minHeight: 25,
          borderRadius: 30,
          margin: 0,
        }}
        onPress={appearExam}
        disabled={
          !userPermission?.isAdmin &&
          !userPermission?.isMarkEdit &&
          type == 'evaluation-pending'
        }
      >
        <I18Text>Retake</I18Text>
      </Button>
    ) : (
      <></>
    )}
  </Row>
) : (
  <></>
)}
          {!(
           !userPermission?.isAdmin && clientDetail?.billing && data?.examCheck?.isAvailableForPurchase
          ) &&
          type !== 'completed' &&
          type !== 'evaluation-pending' ? (
            <Button
              style={{
                minWidth: 75,
                minHeight: 25,
                borderRadius: 30,
                margin: 0,
              }}
              disabled={
                data?.examAvailability !== 'alwaysAvailable' &&
                type == 'upcoming'
              }
              onPress={() =>
                push('/appear-exam/[pageType]', `/appear-exam/${data?._id}`)
              }
            >
             <I18Text style={[sx({ color:'#fff' })]}>Appear</I18Text> 
            </Button>
          ) : (
            type !== 'completed' &&
            type !== 'evaluation-pending' && (
              <Button
                onPress={() => handleBuy(data)}
                variantType="outlined"
                startIcon={<Icon name="cart" width={18} height={18} />}
                startIconStyle={{
                  marginRight: 0,
                }}
                style={{
                  minWidth: 100,
                  minHeight: 25,
                  borderRadius: 30,
                  margin: 0,
                }}
              >
               <I18Text>Buy now</I18Text> 
              </Button>
            )
          )}
        </Row>
      </View>
    </View>
  );
}

export default ExamCard;
